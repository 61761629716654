import React from 'react'
import { CSSTransition } from 'react-transition-group'
import {
  useNavigate,
  useParams,
} from "react-router-dom"
import FileUploader from './file-uploader.js'
import Cards from './cards.js'
import Button, { Select, SwitchButton } from './button.js'
import Selection from './selection.js'
import {isMobile} from 'react-device-detect'
import './app.css'

export const GAMES = {
  swipe: {
    name: "SWIPE",
    game: 'swipe',
    types: [
      {
        type: 'a',
        name: 'Egenskaper',
      },
      {
        type: 'b',
        name: 'Red Flags',
      },
    ]
  },
  rackare: {
    name: "Rackare!",
    game: 'rackare',
    types: [
      {
        type: 'a',
        name: 'Vita kort',
      },
      {
        type: 'b',
        name: 'Svarta kort',
      },
    ]
  }
}

const PRINT = {
  multi: {
    name: 'A4',
    key: 'multi',
    width: '21cm',
    height: '29.7cm',
  },
  single: {
    name: "Kort",
    key: 'single',
    size: {
      swipe: {
        width: '6.5cm',
        height: '6.5cm',
      },
      rackare: {
        width: '2.5in',
        height: '3.5in',
      },
    }
  }
}

var cssPagedMedia = (function () {
  var style = document.createElement('style')
  document.head.appendChild(style)
  return function (rule) {
      style.innerHTML = rule
  }
}())

cssPagedMedia.size = function (width, height) {
  cssPagedMedia(`
    @page {
      margin: 0;
      size: ${width} ${height};
    }
  `)
}

function generateId() {
  return Math.random() * 1e20
}

function defaultCards() {
  return [{
    id: generateId(),
    text: '',
    type: 'a',
    backface: true,
  }, {
    id: generateId(),
    text: '',
    type: 'b',
    backface: true,
  }]
}

function App() {
  const actions = React.useRef({})
  const params = useParams()
  const navigate = useNavigate()
  const [cards, setCards] = React.useState(() => defaultCards())
  const [printType, setPrintType] = React.useState('')
  const [confirmModal, setConfirmModal] = React.useState(false)
  const hasCards = cards.filter(c => !c.backface).length || cards.length === 1

  React.useEffect(() => {
    document.querySelector("#html").className = `game-${params.game}${params.type ? ' type-' + params.type : ''}${printType ? ' print-' + printType : ''}${isMobile ? ' is-mobile' : ' is-desktop'} transition`
  }, [params, printType])

  const handleEditCard = React.useCallback((id, text) => {
    const c = Object.assign([], cards)
    const index = c.findIndex(item => item.id === id)
    const item = c[index]
    item.text = text

    if (text === false) {
      // Remove card
      c.splice(index, 1)
    } else {
      // Edit card
      c.splice(index, 1, item)
    }

    setCards(c)
  }, [cards])

  const handleFileUpload = React.useCallback((file) => {
    /** Remove empty lines */
    const rows = file.split('\r\n').filter(row => (
      row.replace(/ /g, '') !== ''
    )).map(text => ({ text, id: generateId() }))

    rows.unshift({
      id: generateId(),
      text: '',
      type: 'a',
      backface: true,
    })

    setCards(rows)
    navigate(`/game/${params.game}/type/a`)
  }, [params, navigate])

  const handleClearSuccess = React.useCallback(() => {
    setCards(defaultCards())
    navigate(`/game/${params.game}`)
  }, [params, navigate])

  const handleClear = React.useCallback(() => {
    const cardCount = cards.filter(c => !c.backface).length

    if (cardCount) {
      setConfirmModal(
        <ConfirmModal
          text={(
            <span>
              Är du säker på att du vill ta bort <b>{cardCount}</b> kort?
            </span>
          )}
          successText="Ta bort"
          onSuccess={handleClearSuccess}
          onFailure={() => {}}
          onExited={() => {
            setConfirmModal(null)
          }}
        />
      )
    } else {
      handleClearSuccess()
    }
  }, [handleClearSuccess, cards])

  const handleSave = React.useCallback(() => {
    const link = document.createElement("a")
    const file = new Blob(cards.map(c => c.text + '\r\n'), { type: 'text/csv' });

    link.href = URL.createObjectURL(file)
    link.download = `${params.game}-kort.csv`

    link.click()
    URL.revokeObjectURL(link.href)
  }, [params, cards])

  const handleAddCard = React.useCallback(({ type }) => {
    const cardCount = cards.filter(c => !c.backface).length
    const newCardIndex = cards.findIndex(c => c.backface && c.type === type) + 1

    const c = Object.assign([], cards)

    // Unmount other type creator card
    if (!cardCount && cards.length > 1) {
      const index = cards.findIndex(c => c.backface && c.type !== type)
      c[index].unmount = true
    }

    // Add new creator card
    c.unshift({ text: '', id: generateId(), backface: true, type })

    // Remove props from new frontface card
    c[newCardIndex].text = `Kort #${cardCount + 1}`
    delete c[newCardIndex].backface
    delete c[newCardIndex].type

    setCards(c)

    navigate(`/game/${params.game}/type/${type}`)
  }, [cards, params, navigate])

  const handleFormValueChange = React.useCallback(() => {
    const printType = document.querySelector("#form").elements.printType?.value
    setPrintType(printType)
  }, [])

  const handleTypeSwitch = React.useCallback((type) => {
    if (hasCards) {
      const c = Object.assign([], cards)
      c[0].type = type
      setCards(c)
    }

    navigate(`/game/${params.game}/type/${type}`)
  }, [params, cards, hasCards, navigate])

  const handleGameSwitch = React.useCallback((game) => {
    if (game !== params.game) {
      if (params.type) {
        setCards(defaultCards())
      }

      if (game) {
        navigate(`/game/${game}`)
      }
    }
  }, [navigate, params])

  const gameRef = React.useRef(params.game)

  React.useEffect(() => {
    gameRef.current = params.game
    if (params.type && !hasCards) {
      navigate(`/game/${params.game}`)
    }
  }, [params, hasCards, navigate])

  React.useEffect(() => {
    actions.current.addCard = handleAddCard
    actions.current.editCard = handleEditCard
    actions.current.clearCards = handleClear
    actions.current.changeType = handleTypeSwitch
  }, [handleAddCard, handleEditCard, handleClear, handleTypeSwitch])

  React.useEffect(() => {
    const game = gameRef.current
    if (printType === 'single' || printType === 'multi') {
      if (printType) {
        setPrintType(printType)
      }

      if (printType === 'single') {
        cssPagedMedia.size(PRINT[printType].size[game].width, PRINT[printType].size[game].height);
      }

      if (printType === 'multi') {
        cssPagedMedia.size(PRINT[printType].width, PRINT[printType].height);
      }

      if (isMobile) {
        setConfirmModal(
          <ConfirmModal
            text={(
              <span>
                Utskrift ej tillgänglig på mobila enheter. Spara dina kort och ladda upp dem ifrån vår tjänst på desktop istället!
              </span>
            )}
            successText="Ok"
            onSuccess={() => {
              setPrintType('')
            }}
            onExited={() => {
              setConfirmModal(null)
            }}
          />
        )
      } else {
        // Give the page some time to render
        setTimeout(() => {
          window.print()
        }, 1000)
      }
    }
  }, [printType])

  const helpHandler = React.useCallback(() => {
    setConfirmModal(
      <ConfirmModal
        text={(
          <span>
            <b>
              SÅ HÄR GÖR DU DINA EGNA KORT
            </b>
            <p>
              Med kortgeneratorn kan du snabbt och enkelt skriva egna kort till SWIPE och Rackare oskiljaktiga från spelens officella kort.
            </p>
            <p>
              Välj spel och kortsort för att lägga till de korten ditt spel saknar - eller varför inte skriva ett helt eget spel om det nu är så jävla lätt. Sen är det bara att skriva ut, skära till, och börja spela.
            </p>
            <b>
              Hur skriver jag ut på mobil?
            </b>
            <p>
              Utskrift på mobila enheter stöds inte i nuläget. Har du skrivit kort på mobil enhet sparar du enkelt dina kort som .CSV och laddar sedan upp CSV-filen i kortgeneratorn i webbläsare på desktop för att skriva ut. 
            </p>
            <b>
              Vilket format bör jag välja på min utskrift?
            </b>
            <p>
              Välj mellan A4 och kort var för sig. A4 lämpar sig bäst för hemmautskrift, och enstaka kort är bäst ifall du ska skicka till ett utskriftsställe. Då brukar de kunna sköta till korten och ofta även runda hörnen.
            </p>
            <b>
              Hur gör jag för att mina kort ska bli så bra som möjligt?
            </b>
            <p>
              <ul>
                <li>
                  Skaffa det tjockaste vita pappret din skrivare klarar. (Tjockleken på vanliga skrivarpapper är 80gsm. Spelkort är 310gsm.)
                </li>
                <li>
                  Se till att du skriver ut i rätt storlek. (SWIPE-korten måttar 65x65mm och Rackare-korten 63x89mm.)
                </li>
                <li>
                  Ta din tid när du skär till korten. Använd pappersklyv eller linjal och brytbladskniv för bästa resultat, undvik sax. Rundade hörn gör mycket för känslan av riktiga kort.
                </li>
              </ul>
            </p>
          </span>
        )}
        successText="Ok"
        onExited={() => {
          setConfirmModal(null)
        }}
      />
    )
  }, [])

  React.useEffect(() => {
    window.onafterprint = () => {
      setPrintType('')
    }
    return () => {
      window.onafterprint = null
    }
  }, [])

  return (
    <div className={`app`}>
      <form id="form" onChange={(data) => handleFormValueChange(data)} className={`app-header button-container transition no-print app-header__fixed`}>
        <Button.Provider value={{}}>
          <div className="app-header--left button-container">
            <a className="logo-container no-print" href="https://www.ninjaprint.se">
              <img alt="logo" className="logo-source" src="/logo.png" />
            </a>
            <Button secondary onClick={helpHandler}>Hjälp</Button>
          </div>
          <div className="app-header--center button-container">
            <SwitchButton onChange={handleGameSwitch} value={params.game}
              items={Object.values(GAMES).map((item) => ({
                text: item.name,
                value: item.game,
              }))}
            />
          </div>
          <div className="app-header--right button-container">
            {!hasCards ? (
              <FileUploader onSelect={handleFileUpload}>
                <Button secondary>Ladda upp CSV</Button>
              </FileUploader>
            ) : null}
            {hasCards ? (
              <>
                <Button disabled={!params.type} secondary onClick={handleClear}>Rensa</Button>
                {!isMobile ? <Button secondary disabled={!hasCards} onClick={handleSave}>Spara</Button> : null}
              </>
            ) : null}
            {isMobile ? (
              <Button disabled={!hasCards} secondary onClick={handleSave}>Spara</Button>
            ) : null}
            <Select id="print-type" name="printType" disabled={!hasCards} title="Skriv ut" secondary value={''} readOnly>
              {Object.values(PRINT).map((item) => (
                <option key={item.key} value={item.key}>{item.name}</option>
              ))}
            </Select>
          </div>
        </Button.Provider>
      </form>
      <div className="app-content">
        <Selection game={params.game} type={params.type} name={GAMES[params.game].types.find(t => t.type === params.type)?.name} actions={actions} />
        <Cards items={cards} type={params.type} game={params.game} actions={actions} printType={printType} />
      </div>
      {confirmModal}
    </div>
  )
}

function ConfirmModal({ text, successText = "Ok", failureText = "Avbryt", onSuccess, onFailure, onExited }) {
  const nodeRef = React.useRef()
  const [mounted, setMounted] = React.useState(false)

  React.useLayoutEffect(() => {
    setMounted(true)
  }, [])

  const handleOnSuccess = React.useCallback(() => {
    setMounted(false)
    setTimeout(() => {
      onSuccess?.()
    }, 600)
  }, [onSuccess])

  const handleOnFailure = React.useCallback(() => {
    setMounted(false)
    setTimeout(() => {
      onFailure?.()
    }, 600)
  }, [onFailure])

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={mounted}
      timeout={100}
      classNames="modal-transition"
      appear
      unmountOnExit
      onExited={onExited}
    >
      <div
        ref={nodeRef}
        className="app-modal modal-transition"
      >
        <div className="app-modal--container">
          <div className="app-modal--container-text">
            {text}
          </div>
          <div className="button-container">
            {onFailure ? <Button secondary onClick={handleOnFailure}>{failureText}</Button> : null}
            <Button onClick={handleOnSuccess}>{successText}</Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default App
