import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  Navigate,
} from "react-router-dom"

import './variables.css'
import './index.css'
import './print.css'

import App from './app'

/*
function Error() {
  return null
}
*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppValidator />,
    //errorElement: <Error />,
  },
  {
    path: "game/:game",
    element: <AppValidator />,
    //errorElement: <Error />,
  },
  {
    path: "game/:game/type/:type",
    element: <AppValidator />,
    //errorElement: <Error />,
  },
  {
    path: "*",
    element: <AppValidator />,
  }
])

function AppValidator() {
  const params = useParams()

  if (window.location.port !== "3000" && window.location.protocol === 'http:') {
    window.location.href = window.location.href.replace('http:', 'https:')
    return null
  }

  if (params.game && params.game !== 'rackare' && params.game !== 'swipe') {
    return <Navigate to="/" replace />
  }

  if (params.type && params.type !== 'a' && params.type !== 'b') {
    return <Navigate to="/" replace />
  }

  if (!params.game) {
    return <Navigate to="/game/swipe" />
  }

  return <App />
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)