import React from 'react'
import { SwitchButton } from './button'
import { GAMES } from './app'
import './selection.css'

export default function Selection({ game, type, name, actions }) {
  return (
    <div className="selection no-print">
      <div className="selection--header">Gör dina egna kort</div>
      <div className="selection--header-description">Skriv ut eller spara som PDF. Gör några stycken eller <b>tusentals</b>, du väljer!</div>
      <div className="selection--filter">
        {name ? (
          <SwitchButton
            theme={game}
            variant={type}
            inverted={type === 'b'}
            className="selection--filter-type"
            value={type}
            onChange={(type) => actions.current.changeType(type)}
            items={Object.values(GAMES[game].types).map((item) => ({
              text: item.name,
              value: item.type,
            }))}
          />
        ) : (
          <div className="selection--filter-description">
            <div className="selection--filter-description-text transition">
              Välj korttyp för att starta
            </div>
          </div>
        )}
      </div>
    </div>
  )
}