import React from 'react'
import './button.css'

const DefaultComponent = (props) => <div {...props} />
export default function Button({
  mono = false,
  dark = false,
  secondary = false,
  mini = false,
  round = false,
  disabled = false,
  theme,
  inverted = false,
  variant = 'a',
  icon = false,
  className,
  Component = DefaultComponent,
  children,
  ...rest
}) {
  const buttonProps = useButtonProps()
  theme = buttonProps?.theme || theme
  variant = buttonProps?.variant || variant

  className = `button system-font transition-all ${className}`

  if (mono) {
    className += ` button__mono mono-${dark ? 'dark' : 'light'}`
  }

  if (secondary) {
    className += ` button__secondary`
  }

  if (mini) {
    className += ` button__mini`
  }

  if (round) {
    className += ` button__round`
  }

  if (theme) {
    className += ` button__theme-${theme}-${variant}${inverted ? ' inverted' : ''}`
  }

  if (disabled) {
    className += ` disabled`
  }

  return (
    <Component className={className} {...rest}>
      {Component === DefaultComponent && icon && <span className={`button-icon${icon ? ' icon-' + icon : ''}`} />}
      {children}
    </Component>
  )
}

const SelectComponent = ({ children, title, ...rest }) => {
  return (
    <select {...rest}>
      {title && <option disabled value="">{title}</option>}
      {children}
    </select>
  )
}

export function Select(props) {
  return (
    <Button Component={SelectComponent} {...props} />
  )
}

export function SwitchButton({ items, value, onChange, className, ...rest }) {
  const [selected, setSelected] = React.useState(value)
  const handleClick = React.useCallback((value, action) => {
    setSelected(value)
    onChange(value)
    action?.()
  }, [onChange])

  React.useEffect(() => {
    setSelected(value)
  }, [value])

  return (
    <Button className={`switch-button${className ? ' ' + className : ''}`} {...rest}>
      <div className="switch-button--content">
        {items.map(({ text, value, action }) => (
          <div
            key={`switch-action-${value}`}
            className={`switch-button-item${value === selected ? ' selected' : ''} transition-all`}
            onClick={() => handleClick(value, action)}
            children={text}
          />
        ))}
      </div>
    </Button>
  )
}

const ButtonContext = React.createContext()
Button.Provider = ButtonContext.Provider

export function useButtonProps() {
  return React.useContext(ButtonContext)
}