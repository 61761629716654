import React from 'react'
import { CSSTransition } from 'react-transition-group'
import Button from './button.js'
import ContentEditable from 'react-contenteditable'
import './card.css'

function formatText(text) {
  return text
    .replace(/<div>/g, '\n')
    .replace(/<\/div>/g, '')
    .replace(/<p>/g, '\n')
    .replace(/<\/p>/g, '')
    .replace(/<br>/g, '\n')
    .replace(/ {2}/g, ' ')
}

function getImageType({ text, game, type }) {
  if (text && game === 'rackare' && type === 'a') {
    if ((text.match(/ _/g) || []).length > 1) {
      return 'a'
    } else if (text.indexOf(' _') === -1 && text.match(/\?$/)?.length) {
      return 'b'
    } else {
      return ''
    }
  }
}

function getCaretIndex(element) {
  let position = 0;
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
    }
  }
  return position;
}

export default React.memo(Card)

function Card({
  id,
  text,
  game,
  type,
  unmount = false,
  actions = { current: {} },
  backface,
}) {
  const ref = React.useRef()
  const [isFocused, setFocus] = React.useState(false)

  const handleClick = React.useCallback((e) => {
    setTimeout(() => {
      ref.current.getEl().focus()
    }, 300)
    setFocus(true)
  }, [])

  const handleBlur = React.useCallback((e) => {
    setFocus(false)
  }, [])

  const imageType = React.useMemo(() => (
    getImageType({ text, game, type })
  ), [text, game, type])

  const handleChange = React.useCallback((e) => {
    let text = formatText(e.target.value)
    actions.current.editCard(id, text)
  }, [id, actions])

  const handleRemove = React.useCallback((e) => {
    e.stopPropagation()
    setMounted(false)
  }, [])

  const shouldUnmount = React.useCallback(() => {
    actions.current.editCard(id, false)
  }, [id, actions])

  const handleInsertLine = React.useCallback((e) => {
    const element = ref.current.getEl()
    const position = getCaretIndex(element)
    const insert = ' ______ '

    let text = element.innerHTML
    text = text.substring(0, position) + insert + text.substring(position)

    actions.current.editCard(id, text)
  }, [id, actions])

  const handleClearText = React.useCallback((e) => {
    actions.current.editCard(id, '')
    ref.current.getEl().focus()
  }, [id, actions])

  const handleAddCard = React.useCallback(() => {
    actions.current.addCard({ type })
  }, [actions, type])

  React.useLayoutEffect(() => {
    if (unmount) {
      setMounted(false)
    }
  }, [unmount])

  React.useLayoutEffect(() => {
    setMounted(true)
  }, [])

  const showOptions = game === 'rackare'
  const showImage = game === 'rackare'

  const nodeRef = React.useRef()
  const [mounted, setMounted] = React.useState(false)

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={mounted}
      timeout={300}
      classNames="card-transition"
      appear
      unmountOnExit
      onExited={shouldUnmount}
    >
      <div
        ref={nodeRef}
        className="card-transition"
      >
        <div
          onClick={handleClick}
          onBlur={handleBlur}
          className={`card game-${game} type-${type} transition-all${isFocused ? ' focused' : ''}${backface ? ' backface' : ''}`}
        >
          <div className="card-container transition">
            <div className="card-backface transition">
              <div className={`card-backface--background-a transition`} />
              <div className={`card-backface--background-b transition`} />
              <Button onClick={handleAddCard} theme={game} variant={type} inverted={game === 'swipe' && type === 'b'} className="card-backface--add-button">+</Button>
            </div>
            <div className="card-frontface transition">
              <Button theme={game} variant={type} mini onClick={handleRemove} className={`card-remove-button`}>ta bort</Button>
              <div className={`card-content ${isFocused ? 'focused' : ''} transition`}>
                <div className="card-text-content">
                  {typeof text === 'string' && (
                    <ContentEditable
                      ref={ref}
                      className="card-text"
                      html={text}
                      onChange={handleChange}
                    />
                  )}
                </div>
                {showOptions && (
                  <span className="button-container card-text-options transition">
                    {type === 'a' && <Button theme={game} variant={type} mini onClick={handleInsertLine}>tomt fält</Button>}
                    <Button theme={game} variant={type} mini onClickCapture={handleClearText}>rensa</Button>
                  </span>
                )}
                {showImage && (
                  <span className={`card-image image-type-${imageType} transition`} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}


export const PrintCard = React.memo(PrintCardRaw)
function PrintCardRaw({ text, game, type, backface = false, blank = false, className = '' }) {
  const showImage = game === 'rackare'
  const imageType = React.useMemo(() => (
    getImageType({ text, game, type })
  ), [text, game, type])

  return (
    <div
      className={`card game-${game} type-${type}${backface ? ' backface' : ''}${blank ? ' card__blank' : ''} ${className}`}
    >
      <div className="card-container">
        <div className="card-backface transition">
          <div className={`card-backface--background-${type}`} />
        </div>
        <div className="card-frontface">
          <div className="card-content">
            <div className="card-text-content">
              {typeof text === 'string' && (
                <ContentEditable
                  className="card-text"
                  html={text}
                />
              )}
            </div>
            {showImage && (
              <span className={`card-image image-type-${imageType} transition`} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}