import React from 'react'
import Card, { PrintCard } from './card.js'
import './cards.css'
import { useInView } from 'react-intersection-observer'

export default function Cards({
  items,
  type,
  game,
  printType,
  actions,
}) {
  const [printPagesSingle, setPrintPagesSingle] = React.useState([])
  const [printPagesMulti, setPrintPagesMulti] = React.useState([])
  const [printCards, setPrintCards] = React.useState([])

  const rows = React.useMemo(() => {
    const itemCount = (printCards.length - 1)

    return (
      Array.from({ length: Math.ceil(itemCount / 3) }, (_, row) => (
        Array.from({ length: 3 }, () => null)
      ))
    )
  }, [printCards])

  const generatePages = React.useCallback((rows) => {
    const itemsPerPage = game === 'rackare' ? 9 : game === 'swipe' ? 12 : 1
    const rowsPerPage = game === 'rackare' ? 3 : game === 'swipe' ? 4 : 1
    const itemCount = (printCards.length - 1)

    return (
      Array.from({ length: Math.ceil(itemCount / itemsPerPage) }, (_, page) => (
        Array.from({ length: rowsPerPage }, (_, pos) => rows[page * rowsPerPage + pos] || null)
      ))
    )
  }, [printCards, game])

  // Populate print cards
  React.useEffect(() => {
    let cards = []

    if (printType === 'single') {
      items.slice(1).forEach((item, i) => {
        // Single card print
        if (printType === 'single') {
          cards.push(() => <PrintCard game={game} type={type} text={item.text} />)
          cards.push(() => <PrintCard game={game} type={type} backface />)
        }
      })

      setPrintCards(cards)
    }

    if (printType === 'multi') {
      let maxItemCount

      if (game === 'swipe') {
        maxItemCount = 12
      }

      if (game === 'rackare') {
        maxItemCount = 9
      }

      /** Populate cards with items and backfaces */
      items.slice(1).forEach((item, i, total) => {
        const currentCardCount = i + 1

        cards.push(() => <PrintCard game={game} type={type} text={item.text} />)

        // If full page of cards, generate backfaces
        if (!(currentCardCount % maxItemCount)) {
          const backfaceCards = Array(maxItemCount).fill(() => <PrintCard game={game} type={type} backface />)
          cards = cards.concat(backfaceCards)
        }

        // Fill last page with empty cards and generate backfaces
        if (currentCardCount === total.length && currentCardCount % maxItemCount) {
          const frontfaceCount = currentCardCount % maxItemCount
          const emptyFrontfaceCount = maxItemCount - frontfaceCount
          const emptyCards = Array(emptyFrontfaceCount).fill(() => <PrintCard game={game} type={type} blank />)
          const lastBackfaceCards = Array(frontfaceCount).fill(() => <PrintCard game={game} type={type} backface />)
          const lastEmptyCards = Array(maxItemCount - frontfaceCount).fill(() => <PrintCard game={game} type={type} blank />)
          cards = cards.concat([...emptyCards, ...lastBackfaceCards, ...lastEmptyCards])
        }
      })

      setPrintCards(cards)
    }
  }, [items, type, game, printType])

  // Render print cards
  React.useEffect(() => {
    if (printType === 'single') {

      // Generate print pages out of single cards
      setPrintPagesSingle(printCards)

    } else {

      // Generate print pages out of rows of multiple cards
      printCards.forEach((component, i) => {
        const r = Math.floor(i / 3)
        if (rows[r]) {
          rows[r][i % 3] = component
        }
      })

      setPrintPagesMulti(generatePages(rows))
    }
  }, [items, generatePages, rows, printCards, printType])

  return (
    <>
      {/** Print A4 */}
      {printType === 'multi' && printPagesMulti.map((page, p) => (
        <div key={`print-card-page-${p}`} className={`card-page print`}>
          <div key={`print-card-page-content-${p}`} className={`card-page-content`}>
            {page.map((row, r) => (
              <div key={`print-card-row-${r}`} className={`card-row ${p % 2 ? 'card-row--backface' : 'card-row--frontface'}`}>
                {row?.map((Item, i) => (
                  Item ? <Item key={`print-card-item-${i}`} type={type} game={game} /> : null
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      {/** Print single cards */}
      {printType === 'single' && printPagesSingle.map((Item, i) => (
        <div key={`print-card-page-${i}`} className={`card-page print`}>
          <div key={`print-card-page-content-${i}`} className={`card-page-content`}>
            {Item ? <Item key={`print-card-item-${i}`} type={type} game={game} /> : null}
          </div>
        </div>
      ))}
      {/** Display */}
      <div className="card-list no-print">
        {items.map((item) => {
          return (
            <CardContainer
              key={`card-item-${item.id}`}
              item={item}
              game={game}
              type={type}
              actions={actions}
            />
          )
        })}
      </div>
    </>
  )
}

function CardContainer({ item, game, type, actions }) {
  const [ cardRef, inViewport] = useInView()
  return (
    <span
      ref={cardRef}
      className={`${inViewport ? 'in-viewport' : 'outside-viewport'}`}
    >
      <Card
        id={item.id}
        game={item.game || game}
        type={item.type || type}
        text={item.text}
        actions={actions}
        backface={item.type}
        unmount={item.unmount}
      />
    </span>
  )
}